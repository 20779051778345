@use '../../style/variables' as vars;

$apricot: #FFD6BA;

$rose-300: #F67984;
$rose-500: #F45B69;

$yellow-300: #FFB347;
$yellow-500: #FF9F1C;

$nickel-300: #818980;
$nickel-500: #6C756B;

$purple-300: #8F56C8;
$purple-400: #8546C3;
$purple-500: #7A3CB9;






.portfolio__work-card {
  border-radius: 1rem;
  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;
  color: hsla(0, 0%, 100%, 0.85);
  margin-bottom: 6rem;
  transition: all 0.15s ease;

  &:hover {
    .combo-img__wrapper {
      box-shadow: 0px 35px 50px -22px rgb(0 0 0 / 15%);
      // box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    }

    .work-card__img-wrapper {
      transform: scale(1.02);
    }
    .work-card__text-wrapper {
      transform: scale(.98);
    }
  }
}


.work-card__text--padding-top {
  padding-top: 0rem;
}

.work-card__img-wrapper,
.work-card__text-wrapper {
  transition: all 0.15s ease;
}

.work-card__text-wrapper {
  align-self: flex-end;
}

.work-card__body-text,
.work-card__headline {
	text-shadow: 0 0 6px rgb(0 0 0 / 20%);
}


.work-card__headline {
  color: vars.$white;
  font-weight: 800;
  font-size: 2.136rem;
}

.work-card__headline--shrink-width {
  width: 60%;
}


.work-card__img {
  border-radius: 1rem;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  // box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  // shadow-inner	box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
  // shadow-none	box-shadow: 0 0 #0000;
}

.work-card__banner-wrapper {
  filter: drop-shadow(0 10px 10px rgba(82, 33, 0, 0.2));
  position: absolute;
  top: -3px;
  left: 3rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 10rem;
  
  
  &.right-align {
    left: unset;
    right: 3rem;
  }
}

.work-card__banner {
  clip-path: polygon(100% 0, 100% 78%, 50% 100%, 0 78%, 0 0);
  background-color: $apricot;
  color: vars.$blue-800;
  text-align: center;
  
  .banner__icon {
    color: vars.$blue-300;
    color: $yellow-500;
    filter: drop-shadow(0 0 4px rgba($yellow-500, 0.4));
  }
}


.layout--alt-left {
  .combo-img__wrapper--2 {
    position: relative;

    .combo-img__img {
      max-height: 560px;
    }

    .combo-img__img--1,
    .combo-img__img--2 {
      top: unset;
    }

    .combo-img__img--1 {
      width: 30%;
      height: auto;
      z-index: 2;
      padding-right: 0;
      bottom: 0;
      position: absolute;
      right: 0;
    }

    .combo-img__img--2 {
      top: 0;
      z-index: 1;
      text-align: right;
      padding-left: 0;
      max-width: 80%;
      height: auto;
    } 
  }
}

.work-card__loan-app-image-wrapper--alt {
  min-height: 30rem;
  position: relative;
}

.work-card__component-library-image,
.work-card__loan-app-image--alt {
	position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.work-card__component-library-image {
	background-image: url('../../img/component-library--no-bg.png');
}

.work-card__loan-app-image--alt {
	background-image: url('../../img/loan-app-collage--no-bg.png');
}

.work-card__logo-wrapper {
	background-color: rgba(62, 62, 62, 0.18);
}

.work-card__img--tooling-logo svg {
	height: 1.5rem;
	width: auto;
}





.bg-gradient-blue--angle {
  background: linear-gradient(135deg, vars.$blue-400, vars.$blue-600);
}
.right-align.bg-gradient-blue--angle {
  background: linear-gradient(45deg, vars.$blue-600, vars.$blue-400);
}


.bg-gradient-rose {
  background: linear-gradient(135deg, $rose-300, $rose-500);
}
.right-align.bg-gradient-rose {
  background: linear-gradient(45deg, $rose-500, $rose-300);
}


.bg-gradient-yellow {
  background: linear-gradient(135deg, $yellow-300, $yellow-500);
}
.right-align.bg-gradient-yellow {
  background: linear-gradient(45deg, $yellow-500, $yellow-300);
}

.bg-gradient-purple {
  background: linear-gradient(135deg, $purple-300, $purple-500);
}
.right-align.bg-gradient-purple {
  background: linear-gradient(45deg, $purple-500, $purple-300);
}





@media only screen and (max-width: 575px) {
  .work-card__banner-wrapper {
    padding-right: 1rem;
    padding-left: 1rem;
    width: 7rem;
  }
}

@media only screen and (min-width: 575px) {
  .work-card__headline--shrink-width {
    width: 80%;
  }
}

@media only screen and (min-width: 992px) {
  .work-card__text--padding-top {
    padding-top: 3.5rem;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .work-card__headline--shrink-width {
    width: 60%;
  }
}

@media only screen and (min-width: 1200px) {
  .work-card__headline--shrink-width {
    width: 100%;
  }

  .work-card__text--padding-top {
    padding-top: 18rem;
  }

	.portfolio__work-card--with-banner {
		padding-top: 7.95rem;
	}
}