@use '../style/variables' as vars;

nav {
  position: sticky;
  top: 0;
  z-index: 10;
	transition: all 0.25s ease-in-out;

	&.nav--transparent {
		background-color: transparent;
	}
	
	&.nav--solid {
		background-color: vars.$white;
		box-shadow: 0 10px 50px 0 rgb(74 144 226 / 8%);
	}
}

.nav__headline {
  cursor: pointer;
  font-weight: 700;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  color: vars.$blue-300;
  background-image: vars.$gradient-blue-200;
  background-clip: text;
  -webkit-background-clip: text;
  
  
  &:focus,
  &:hover {
    background-image: vars.$gradient-green-200;
  }
}

.nav__nav-link {
  color: vars.$blue-800;

  &:hover {
    color: vars.$blue-500;
  }
}

.nav__github-svg svg{
	height: 1.25rem;
	width: 1.25rem;
}