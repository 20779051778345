@use './variables' as vars;



.link {
  text-decoration: none;
  transition: all 0.15s ease;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}


.dg-btn {
  @extend .ripple;
  @extend .ripple--dark;
  padding: 1rem 2rem;
  border: none;
  border-radius: .75rem;
  transition: all 0.15s ease;
  color: vars.$blue-800;
  outline-style: solid;
  outline-color:  white;
  outline-width: 0px;
  outline-offset: 0px;
  transition: all 0.15s ease;
  
  &:hover,
  &:active {
    color: vars.$blue-500;
    box-shadow: inset 0 2px 10px 0 rgba(vars.$blue-800, .1);
    outline-width: 1px;
    outline-offset: 1px;
  }

  &.dg-btn--white {
    background-color: white;
  }
}





.text-gradient--blue,
.text-gradient--green {
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  color: vars.$blue-300;
  background-clip: text;
  -webkit-background-clip: text;
}

.text-gradient--blue {
  background-image: vars.$gradient-blue-200;
}

.text-gradient--green {
  background-image: vars.$gradient-green-200;
}





/*
 * WHITESPACE
 */
.whitespace-nowrap {
  white-space: nowrap;
}




/**
 * BUTTON RIPPLE ANIMATION
 */
 .ripple {
  position: relative;
	overflow: hidden;

	&:after {
		content: "";
		background: rgba(white, 0.2);
		display: block;
		position: absolute;
		border-radius: 50%;
		padding-top: 240%;
		padding-left: 240%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0;
		transition: all 0.5s;
	}

	&:active:after {
		padding-top: 0;
		padding-left: 0;
		margin-top: 0;
		margin-left: 0;
		opacity: 1;
		transition: 0s;
	}

}

.ripple--dark:after {
	background: rgba(vars.$blue-800, 0.05);
}