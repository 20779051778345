@use '../../style/variables' as vars;


.combo-img__wrapper {
  border-radius: 1rem;
  background-color: white;
  padding: 1rem;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  transition: all 0.15s ease;
}

.combo-img__img {
  max-width: 100%;
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 4px;
}

.combo-img__shadow-wrapper {
  position: relative;
  overflow: hidden;
  max-height: 560px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
	
	.combo-img__img--1 {
		filter: drop-shadow(0 0 4px rgba(vars.$blue-800, 0.8));
		padding: 0.75rem;
	}
}

.combo-img__img--1 {
  top: 1rem;
}

.combo-img__img--2 {
  top: -4rem;
}

.combo-img__wrapper--2 {
  .combo-img__img--1 {
    padding-right: 20%;
  } 
  .combo-img__img--2 {
    padding-left: 20%;
  } 
}