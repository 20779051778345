#homepage {
	background-image: url('../../img/bg-pattern.svg');
  background-position: 50% 0%;
  background-size: auto 800px;
  background-repeat: repeat-x;
  background-attachment: scroll;
	margin-top: -72px;
	padding-top: 12.5rem;
}

.hero-section__headline {
  font-size: 3rem;
  font-weight: 700;
}

.hero-section__headshot-wrapper {
  max-width: 22rem;
}

.homepage__section-header {
  margin-top: 6rem;
}