/**
 * GLOBAL STYLES
 */
html, body {
  font-size: 16px;
}



/**
 * APP STYLES
 */
.app-wrapper {
  width: 100%;
  position: relative;
	height: 100vh;
	overflow: auto;
}