@import url('https://fonts.googleapis.com/css2?family=Sora:wght@400;600;700;800&display=swap');
@import './style/variables';

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
$font-family-sans-serif: 'Sora', 'Arial', sans-serif;
$body-color: $blue-800;
$enable-negative-margins: true;

// 3. Include remainder of required Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

// map extra colors to theme
$custom-theme-colors: (
  "blue-100": $blue-100,
  "blue-200": $blue-200,
  "blue-300": $blue-300,
  "blue-400": $blue-400,
  "blue-500": $blue-500,
  "blue-600": $blue-600,
  "blue-800": $blue-800,
);

// merge new colors with existing theme
$theme-colors: map-merge($theme-colors, $custom-theme-colors);

@import "./style/utilities.scss";

@import "../node_modules/bootstrap/scss/root";

// 4. Include any optional Bootstrap CSS as needed
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/helpers";

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../node_modules/bootstrap/scss/utilities/api";

// 6. Add additional custom code here

.bg-gradient-green-200 {
  background: $gradient-green-200;
}
.bg-gradient-blue-100 {
  background: $gradient-blue-100;
}
.bg-gradient-blue-200 {
  background: $gradient-blue-200;
}
.bg-gradient-blue-400 {
  background: $gradient-blue-400;
}

.max-w-xs {
  max-width: 575px;
}

.no-underline {
	text-decoration: none;
}